import { useState, useEffect, useContext } from 'react'

import Data, { IData } from '../context/DataContext'
import DateRange, { IDateRange } from '../context/DateRangeContext'

export function useAwakeBool() {
	const [state, set] = useState(false)
	useEffect(() => { if (!state) set(true) }, [state])
	return state
}

export function useData() {
	const data = useContext(Data) as IData
	return data
}

export function useDateRange() {
	const dateRange = useContext(DateRange) as IDateRange
	return dateRange
}

export function useOutsideAlerter(ref: any, callback: () => void) {
	useEffect(() => {
		// Alert if clicked on outside of element
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback()
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [ref])
}
