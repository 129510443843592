import React, { FC, useRef, useState } from "react";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { useData, useDateRange, useOutsideAlerter } from "../hooks";
import { Text } from "../components/UI";
import Select, { SingleValue } from "react-select";
import DateRangeSelector from "../components/DateRangeSelector";

const SReport = styled.div`
    padding: 2rem 8rem;
    padding-bottom: 20rem;
    display: grid;
    grid-gap: 1rem;
`;
const SReports = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
`;

const STopRow = styled.div`
    display: grid;
    position: relative;
    margin-left: auto;
`;

const SLine = styled.div`
    background-color: ${(p) => p.theme.colors.neutral["20"]};
`;

const SDateRange = styled.div`
    border: 0.1rem solid ${(p) => p.theme.colors.neutral["20"]};
    border-radius: 10px;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: min-content 0.08rem min-content;
    grid-gap: 1rem;

    cursor: pointer;
    :hover {
        border: 0.1rem solid ${(p) => p.theme.colors.main.primary};
        ${Text} {
            color: ${(p) => p.theme.colors.main.primary};
        }
        ${SLine} {
            background-color: ${(p) => p.theme.colors.main.primary};
        }
    }
`;

const Report = () => {
    const dateRange = useDateRange();
    const ref = useRef();
    useOutsideAlerter(ref, () => dateRange.setIsActive(false));

    return (
        <SReport>
            {/* @ts-ignore */}
            <STopRow ref={ref}>
                <SDateRange onClick={() => dateRange.setIsActive((p) => !p)}>
                    <Text H5 middle center semiBold oneline>
                        {dateRange.range.startDate.toDateString()}
                    </Text>
                    <SLine />
                    <Text H5 middle center semiBold oneline>
                        {dateRange.range.endDate.toDateString()}
                    </Text>
                </SDateRange>
                <DateRangeSelector />
            </STopRow>

            <SReports>
                <Graph title="Video Play" type="VideoPlay" />
                <Graph title="Camera Capture" type="CameraCapture" />
                <Graph title="Placement" type="Placement" />
                <Graph title="Game" type="Game" />
                <Graph title="Tracker" type="Tracker" />
            </SReports>
        </SReport>
    );
};

const SGraph = styled.div`
    height: 26rem;
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: min-content auto min-content min-content;
    padding-bottom: 2rem;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 10px;
    padding: 1rem;
`;

const Graph: FC<{ title: string; type: string }> = ({ title, type }) => {
    const dataManager = useData();
    const dateRange = useDateRange();
    const [selected, setSelected] =
        useState<SingleValue<{ value: string; label: string }>>();
    const options = useMemo(() => {
        const o = Object.keys(
            dataManager?.state?.types?.[type]?.activities ?? {}
        ).map((key) => ({ value: key, label: key }));
        const defaultActivity = dataManager.getMostPopulatedActivity({
            type,
            from: dateRange.range.startDate,
            to: dateRange.range.endDate,
        });
        if (dataManager?.state?.types?.[type]?.activities?.[defaultActivity])
            setSelected({ value: defaultActivity, label: defaultActivity });
        else if (o?.[0]) setSelected(o[0]);
        return o;
    }, [dataManager.state?.types]);
    const dataMemo = useMemo(() => {
        if (selected)
            return dataManager.getData({
                type,
                activityName: selected.value,
                from: dateRange.range.startDate,
                to: dateRange.range.endDate,
            });
    }, [dataManager.state?.types, selected, dateRange.range]);

    const data = dataMemo?.data;
    const totalPlayCount = dataMemo?.totalPlayCount;

    const theme = useTheme();

    return (
        <SGraph>
            <Text H4 semiBold>
                {title}
            </Text>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={5}
                    height={300}
                    barSize={25}
                    // @ts-ignore
                    data={data}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="plays" fill={theme.colors.main.primary} />
                </BarChart>
            </ResponsiveContainer>
            <Text H5 semiBold>
                Total Play Count: {totalPlayCount}
            </Text>
            <Select options={options} value={selected} onChange={setSelected} />
        </SGraph>
    );
};

export default Report;
