import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ThemeProvider } from 'styled-components'
import theme from './brand/theme'

const Container = () => {
    return (
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<React.StrictMode> <Container /> </React.StrictMode>)
