import React, { useRef } from 'react'
import styled from 'styled-components'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { useDateRange, useOutsideAlerter } from '../hooks'

const SDateRangeSelector = styled.div<{ isActive: boolean }>`
    position: absolute;
    right: 0;
    transition: .3s;
    transform: translateY(100%);
    background-color: ${p => p.theme.colors.neutral['00']};
    width: min-content;
    height: min-content;
    z-index: 1;
    padding: 1rem;
    border-radius: 10px;
    border: .1rem solid ${p => p.theme.colors.neutral['20']};

    opacity: ${p => p.isActive ? 1 : 0};
    bottom: ${p => p.isActive ? '-1rem' : 0};
    pointer-events: ${p => p.isActive ? 'all' : 'none'};
`

const DateRangeSelector = () => {
    const dateRange = useDateRange()

    return (
        <SDateRangeSelector isActive={dateRange.isActive}>
            <DateRangePicker
                ranges={[dateRange.range]}
                onChange={e => dateRange.rangeChangeHandler(e)}
            />
        </SDateRangeSelector>
    )
}

export default DateRangeSelector