import React from 'react'
import styled from 'styled-components'
import { Image } from './UI'
import logo from '../brand/logo.png'

const SSidebar = styled.div`
    background-color: ${p => p.theme.colors.main.secondary};
`

const SLogo = styled.div`
    height: 8rem;
    padding: 1rem;
`

const Sidebar = () => {
    return (
        <SSidebar>
            <SLogo>
                <Image image={logo} contain />
            </SLogo>
        </SSidebar>
    )
}

export default Sidebar