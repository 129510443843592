import React, { FC } from 'react'
import styled from 'styled-components'

import Sidebar from './components/Sidebar'

// Context.
import { DataContext } from './context/DataContext'
import { DateRangeContext } from './context/DateRangeContext'

// Pages.
import Home from './pages/Home'
import Report from './pages/Report'

const SApp = styled.div``

const SLayout = styled.div`
    display: grid;
    grid-template-columns: 9rem auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

const SContent = styled.div`
    background-color: ${p => p.theme.colors.neutral['05']};
    height: inherit;
    overflow: auto;
`

function App() {
    return (
        <Context>
            <SApp>
                <SLayout>
                    <Sidebar />
                    <SContent>
                        {/* <Home /> */}
                        <Report />
                    </SContent>
                </SLayout>
            </SApp>
        </Context>
    )
}

const Context: FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <DateRangeContext>
            <DataContext>
                {children}
            </DataContext>
        </DateRangeContext>
    )
}

export default App
