import React, { FC, useState, useEffect } from "react";
const JSON_URL =
    "https://woolworthsar.firebaseio.com/flamelink/environments/production/content/tdanalytics/discoveryTours.json";

const Data = React.createContext({});

interface Dictionary<T> {
    [key: string]: T;
}

interface IType {
    id: string; // Type id.
    activities: Dictionary<Date[]>;
}

interface IBar {
    name: string;
    plays: number;
}

export interface IDataState {
    types: Dictionary<IType>;
}

export interface IData {
    state: IDataState;
    getData(data: {
        type: string;
        activityName: string;
        from: Date;
        to: Date;
    }): { data: IBar[]; totalPlayCount: number };
    getMostPopulatedActivity(data: {
        type: string;
        from: Date;
        to: Date;
    }): string;
}

export const DataContext: FC<{ children?: React.ReactNode }> = (props) => {
    const [state, set] = useState<IDataState>({
        types: {},
    });

    useEffect(() => {
        (async function () {
            const response = await fetch(JSON_URL);
            const users = await response.json(); // Get users from JSON database.

            const types: Dictionary<IType> = {};
            for (const user of Object.values(users) as any[]) {
                for (const record of user?.activityRecords ?? []) {
                    if (!(record.activityType in types))
                        types[record.activityType] = {
                            // Initialize new type.
                            id: record.activityType,
                            activities: {},
                        };

                    // Initialize new activity name.
                    if (
                        !(
                            record.activityName in
                            types[record.activityType].activities
                        )
                    )
                        types[record.activityType].activities[
                            record.activityName
                        ] = [];

                    ((record?.timeStamps ?? []) as string[]).forEach((ts) => {
                        const date: Date = new Date(ts); // Get date from timestamp.
                        if (date)
                            types[record.activityType].activities[
                                record.activityName
                            ].push(date); // Save date.
                    });
                }
            }

            set((p) => ({ ...p, types }));
        })();
    }, []);

    const value: IData = {
        state,
        getData({ type, activityName, from, to }) {
            const logs: Date[] = (
                state.types?.[type]?.activities?.[activityName] ?? []
            ) // Get the activity logs.
                .filter((date) => date >= from && date <= to) // Get dates between selected time range.
                .sort((a, b) => (a > b ? 1 : -1)); // Sort by date.

            const bars: Dictionary<number> = {};
            let totalPlayCount = 0;

            logs.forEach((date) => {
                const key = `${date.getMonth()}/${date.getDate()}`; // Get day.
                if (!(key in bars)) bars[key] = 0; // Initialize counter.
                bars[key]++; // Count up
                totalPlayCount++;
            });

            return {
                data: Object.entries(bars).map(([name, plays]) => ({
                    name,
                    plays,
                })),
                totalPlayCount,
            };
        },
        getMostPopulatedActivity({ type, from, to }) {
            const activityLogs: Dictionary<Date[]> = Object.entries(
                state.types?.[type]?.activities ?? {}
            ).reduce(
                (obj, [activityName, dates]) => ({
                    ...obj,
                    [activityName]:
                        dates.filter((date) => date >= from && date <= to) ??
                        [],
                }),
                {}
            ); // Get all logs for each activity name.
            let winner: string = "";
            Object.entries(activityLogs).forEach(([activityName, dates]) => {
                // Find what activity name has the most logs.
                if (winner === "") winner = activityName;
                else {
                    if (activityLogs[winner].length < dates.length)
                        winner = activityName;
                }
            });

            return winner; // And return the winner activity.
        },
    };

    return <Data.Provider value={value}>{props.children}</Data.Provider>;
};

export default Data;
