import React, { FC, useState, useEffect } from 'react'

const DateRange = React.createContext({})

interface Dictionary<T> { [key: string]: T }

export interface IDateRangeState {
    startDate: Date,
    endDate: Date,
    key: string
}

export interface IDateRange {
    range: IDateRangeState,
    rangeChangeHandler(e: any): void
    isActive: boolean,
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const DateRangeContext: FC<{ children?: React.ReactNode }> = (props) => {
    const [range, setRange] = useState<IDateRangeState>({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
        key: 'selection'
    })
    const [isActive, setIsActive] = useState(false)

    const value: IDateRange = {
        range,
        rangeChangeHandler(e: any): void { setRange(e['selection']) },
        isActive,
        setIsActive
    }

    return (
        <DateRange.Provider value={value}>
            {props.children}
        </DateRange.Provider>
    )
}

export default DateRange